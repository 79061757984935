import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const ArboladoHeader = () => {
  const location = useLocation()

  return (
    <header className="Arbo-header">
      <h1>1115 Arbolado</h1>
      <nav className="main-nav-arb">
        <ul className="main-nav-arb-list">
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/front') ? 'selected' : 'not-selected'}`}
              to="/arbolado/front"
            >
              Front Door
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/entryway') ? 'selected' : 'not-selected'}`}
              to="/arbolado/entryway"
            >
              Entryway
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/livingroom') ? 'selected' : 'not-selected'}`}
              to="/arbolado/livingroom"
            >
              Living Room
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/balcony') ? 'selected' : 'not-selected'}`}
              to="/arbolado/balcony"
            >
              Balcony
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/courtyard') ? 'selected' : 'not-selected'}`}
              to="/arbolado/courtyard"
            >
              Courtyard
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/dining') ? 'selected' : 'not-selected'}`}
              to="/arbolado/dining"
            >
              Dining
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/atrium') ? 'selected' : 'not-selected'}`}
              to="/arbolado/atrium"
            >
              Atrium
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/kitchen1') ? 'selected' : 'not-selected'}`}
              to="/arbolado/kitchen1"
            >
              Kitchen 1
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/kitchen2') ? 'selected' : 'not-selected'}`}
              to="/arbolado/kitchen2"
            >
              Kitchen 2
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/balcony2') ? 'selected' : 'not-selected'}`}
              to="/arbolado/balcony2"
            >
              Balcony 2
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/rooftop') ? 'selected' : 'not-selected'}`}
              to="/arbolado/rooftop"
            >
              Rooftop
            </Link>
          </li>
          <li>
            <Link
              className={`${location.pathname.includes('/arbolado/lower') ? 'selected' : 'not-selected'}`}
              to="/arbolado/lower"
            >
              Lower Level
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default ArboladoHeader
