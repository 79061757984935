import React from 'react'
import { useParams, Navigate } from 'react-router-dom'
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer'

const imageMap = {
  livingroom: '/photos/arbolado/living-room.jpg',
  balcony: '/photos/arbolado/balcony.jpg',
  atrium: '/photos/arbolado/atrium.jpg',
  courtyard: '/photos/arbolado/courtyard.jpg',
  front: '/photos/arbolado/front.jpg',
  entryway: '/photos/arbolado/entry.jpg',
  dining: '/photos/arbolado/dining.jpg',
  kitchen1: '/photos/arbolado/kitchen1.jpg',
  kitchen2: '/photos/arbolado/kitchen2.jpg',
  balcony2: '/photos/arbolado/balcony2.jpg',
  rooftop: '/photos/arbolado/rooftop.jpg',
  lower: '/photos/arbolado/lower.jpg',
}

const Arbolado = () => {
  const { view } = useParams()

  const imageSrc = imageMap[view] || imageMap.livingroom

  if (!view) {
    return <Navigate to="/arbolado/livingroom" />
  }

  return (
    <div>
      <div className="360viewer">
        <ReactPhotoSphereViewer
          key={imageSrc} // Force re-mount when imageSrc changes
          src={imageSrc}
          height={'90vh'}
          width={'100%'}
        />
      </div>
    </div>
  )
}

export default Arbolado
