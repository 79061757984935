import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from './logo.svg'

class Header extends Component {
  render() {
    const { location } = this.props

    if (!location) {
      return <header>Loading...</header>
    }

    if (location.pathname === '/arbolado') {
      return (
        <header>
          <h1>1115 Arbolado</h1>
        </header>
      )
    }

    return (
      <header className="App-header">
        <img src={logo} className="App-logo" height={100} alt="Christopher Hujanen Photography" />
        <div className="spacer"></div>
        <nav className="main-nav">
          <ul className="main-nav-list">
            <li>
              <Link className={`${location.pathname === '/nature' ? 'selected' : 'not-selected'}`} to="/nature">
                NATURE
              </Link>
            </li>
            <li>
              <Link className={`${location.pathname === '/sports' ? 'selected' : 'not-selected'}`} to="/sports">
                SPORTS
              </Link>
            </li>
            <li>
              <Link className={`${location.pathname === '/people' ? 'selected' : 'not-selected'}`} to="/people">
                PEOPLE
              </Link>
            </li>
            <li>
              <Link className={`${location.pathname === '/travel' ? 'selected' : 'not-selected'}`} to="/travel">
                TRAVEL
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    )
  }
}

export default Header
