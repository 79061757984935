export const peoplePhotos = [
  {
    src: '/photos/people/Cece-yellow.jpg',
    width: 5,
    height: 4,
  },
  {
    src: '/photos/people/CPH_0294.jpg',
    width: 7,
    height: 5,
  },
  {
    src: '/photos/people/Ally-2020-4465-Edit.jpg',
    width: 5,
    height: 4,
  },
  {
    src: '/photos/people/_HUJ1022.jpg',
    width: 2,
    height: 3,
  },
  {
    src: '/photos/people/257_2904.jpg',
    width: 7,
    height: 5,
  },
  {
    src: '/photos/people/DSC_0121.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/people/Ahsan-2928-Edit.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/people/_DSC9630-Edit.jpg',
    width: 5,
    height: 4,
  },
  {
    src: '/photos/people/CPH_7377_Luminar2018-edit.jpg',
    width: 1,
    height: 1,
  },
  {
    src: '/photos/people/kailey-2019-3738-Edit.jpg',
    width: 5,
    height: 7,
  },
  {
    src: '/photos/people/_HUJ1508.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/people/ally-2019-3770_Luminar3-edit.jpg',
    width: 3,
    height: 2,
  },
];

export const naturePhotos = [
  {
    src: '/photos/nature/_DSC3333-Edit.jpg',
    width: 7,
    height: 5,
  },
  {
    src: '/photos/nature/_HUJ2858_comet-neowise.jpg',
    width: 7,
    height: 5,
  },
  {
    src: '/photos/nature/279_1348_49_50_51_52_53_54.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/nature/_DSC1505.jpg',
    width: 7,
    height: 5,
  },
  {
    src: '/photos/nature/_DSC1854.jpg',
    width: 16,
    height: 9,
  },
  {
    src: '/photos/nature/_HUJ2355.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/nature/DSC_1772.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/nature/300_6114_HDR.jpg',
    width: 7,
    height: 5,
  },
  {
    src: '/photos/nature/DSC_2525-Edit.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/nature/276_9863-cropped.jpg',
    width: 5,
    height: 4,
  },
];

export const travelPhotos = [
  {
    src: '/photos/travel/_DSC1990_Luminar-edit.jpg',
    width: 7,
    height: 5,
  },
  {
    src: '/photos/travel/_DSC0366_Luminar2018-edit2.jpg',
    width: 5,
    height: 4,
  },
  {
    src: '/photos/travel/_HUJ1357-Edit.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/travel/HUJ_0588.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/travel/_HUJ1300-Edit.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/travel/HUJ_0330_Luminar4-edit.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/travel/HUJ_0534.jpg',
    width: 16,
    height: 9,
  },
  {
    src: '/photos/travel/SigCabinHDR-Edit.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/travel/SigOlsonInside-Edit.jpg',
    width: 3,
    height: 2,
  },
];

export const sportsPhotos = [
  {
    src: '/photos/sports/HUJ_8234_brickos-hill.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/sports/HUJ_6606_alaskan-tennis.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/sports/HUJ_7168_Cece-backhand.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/sports/HUJ_7993_Reece-second.jpg',
    width: 5,
    height: 4,
  },
  {
    src: '/photos/sports/HUJ_7717.jpg',
    width: 1,
    height: 1,
  },
  {
    src: '/photos/sports/DSC_0281.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/sports/HUJ_2422.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/sports/DSC_7971.jpg',
    width: 5,
    height: 4,
  },
  {
    src: '/photos/sports/HUJ_9021.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/sports/DSC_9996.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/sports/DSC_0015.jpg',
    width: 16,
    height: 9,
  },
  {
    src: '/photos/sports/HUJ_6223.jpg',
    width: 5,
    height: 7,
  },
  {
    src: '/photos/sports/HUJ_4930.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/sports/CPH_4158.jpg',
    width: 1,
    height: 1,
  },
  {
    src: '/photos/sports/DSC_1625.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/sports/DSC_1842.jpg',
    width: 5,
    height: 4,
  },
  {
    src: '/photos/sports/HUJ_0249.jpg',
    width: 5,
    height: 4,
  },
  {
    src: '/photos/sports/DSC_2113.jpg',
    width: 5,
    height: 7,
  },
  {
    src: '/photos/sports/DSC_9570.jpg',
    width: 5,
    height: 4,
  },
  {
    src: '/photos/sports/HUJ_3406.jpg',
    width: 5,
    height: 4,
  },
  {
    src: '/photos/sports/HUJ_8885.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/sports/HUJ_6815.jpg',
    width: 1,
    height: 1,
  },
  {
    src: '/photos/sports/_DSC1816.jpg',
    width: 16,
    height: 9,
  },
  {
    src: '/photos/sports/HUJ_1424.jpg',
    width: 3,
    height: 2,
  },
  {
    src: '/photos/sports/HUJ_1788.jpg',
    width: 16,
    height: 9,
  },
  {
    src: '/photos/sports/HUJ_1996.jpg',
    width: 3,
    height: 2,
  },
];
