import React, { Component } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import './App.css'
import Sports from './Sports'
import People from './People'
import Nature from './Nature'
import Travel from './Travel'
import Arbolado from './Arbolado'
import Header from './Header'
import ArboladoHeader from './ArboladoHeader'

const HeaderWrapper = () => {
  const location = useLocation()

  if (location.pathname.startsWith('/arbolado')) {
    return <ArboladoHeader />
  }

  return <Header location={location} />
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
    }

    this.showMenu = this.showMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  showMenu(event) {
    event.preventDefault()

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu)
    })
  }

  closeMenu(event) {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu)
    })
  }

  render() {
    return (
      <Router>
        <div className="App">
          <HeaderWrapper />
          <Routes>
            <Route path="/nature" element={<Nature />} />
            <Route path="/sports" element={<Sports />} />
            <Route path="/people" element={<People />} />
            <Route path="/travel" element={<Travel />} />
            <Route path="/arbolado" element={<Navigate to="/arbolado/livingroom" />} />
            <Route path="/arbolado/:view" element={<Arbolado />} />
            <Route path="/" element={<Nature />} />
          </Routes>
          <footer>Copyright &copy; 2010-{new Date().getFullYear()} Christopher Hujanen. All rights reserved.</footer>
        </div>
      </Router>
    )
  }
}

export default App
